import { MDXProvider } from '@mdx-js/react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { BlogCta } from '../components/BlogCta'
import Layout from '../components/Layout'
import { BlogPostSchema } from '../components/Schema'
import SecondaryCTA from '../components/SecondaryCTA'
import SidebarLayout from '../components/SidebarLayout'
import Social from '../components/Social'
import { TableOfContents } from '../components/TableOfContents'
import { YoutubeVideo } from '../components/YoutubeVideo'
import { InlineQuote } from '../components/blocks/InlineQuote'
import { CoverImage } from '../components/blog/CoverImage'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import theme from '../styles/theme'
import { Heading, parseToc } from '../tocUtils'

export default function BlogTemplate({ data }) {
  const { mdx } = data
  const { frontmatter, body, wordCount } = mdx

  const url = 'https://www.swarmia.com' + frontmatter.slug
  const title = (frontmatter.meta.seoTitle || frontmatter.title) + ' | Swarmia'
  const description = frontmatter.meta.description

  const imageData = frontmatter?.meta?.image?.childImageSharp?.original
  const image = imageData?.src
    ? 'https://www.swarmia.com' + imageData.src
    : undefined
  const imageWidth = String(imageData?.width)
  const imageHeight = String(imageData?.height)

  const cover = getImage(frontmatter.meta.image)
  const avatar = getImage(frontmatter.author.profilepicture)

  const isLongArticle = frontmatter.articleType === 'long'
  const [headings, setHeadings] = useState<Heading[] | null>(null)
  const setContentRef = useCallback(
    node => {
      if (isLongArticle && node) {
        const headings = ['h2', 'h3', 'h4'].slice(0, frontmatter.tocDepth || 2)
        setHeadings(parseToc(Array.from(node.querySelectorAll(headings))))
      }
    },
    [frontmatter.tocDepth, isLongArticle],
  )
  const titleId = 'top'

  const titleSection = (
    <>
      <Box
        font="blogH1"
        id={titleId}
        css={`
          scroll-margin-top: 100px;
        `}
        maxWidth="blogContent"
      >
        {frontmatter.title}
      </Box>
      <Box
        font="blogMetadata"
        color="black500"
        paddingTop={16}
        paddingBottom={48}
      >
        {frontmatter.author.name}, {frontmatter.author.jobTitle} ·{' '}
        <span style={{ whiteSpace: 'nowrap' }}>{frontmatter.date}</span>
      </Box>
      {cover && frontmatter.showCoverImage && (
        <Box paddingBottom={64}>
          <CoverImage image={cover} alt={frontmatter.meta.imageAlt} />
          {frontmatter.meta.imageAlt ? (
            <Box font="blogImageAlt" paddingTop={24}>
              {frontmatter.meta.imageAlt}
            </Box>
          ) : null}
        </Box>
      )}
    </>
  )
  const content = (
    <>
      <Box
        ref={setContentRef}
        className={`prose ${isLongArticle ? 'use-small-outset' : ''}`}
      >
        <MDXProvider
          components={{
            // NOTE: If we start having many components here, implement lazy loading to keep things fast
            // See: https://www.gatsbyjs.com/docs/mdx/importing-and-using-components/#lazy-loading-components
            BlogCta,
            blockquote: props => <InlineQuote quote={props.children} />,
            YoutubeVideo: props => <YoutubeVideo borderRadius={0} {...props} />,
          }}
        >
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </Box>
    </>
  )
  const footer = (
    <Box paddingTop={64}>
      <Bio>
        {avatar && (
          <div className="bio-avatar">
            <GatsbyImage image={avatar} alt={frontmatter.author.name} />
          </div>
        )}
        <div className="bio-text">
          <span className="bio-name">{frontmatter.author.name}</span>{' '}
          {frontmatter.author.bio}
        </div>
      </Bio>
      <Social />
    </Box>
  )

  return (
    <Layout justifyCenter isNew>
      <Helmet title={title}>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta name="author" content={frontmatter.author.name} />
        <meta property="og:url" content={url} />
        <meta
          property="og:title"
          content={frontmatter.meta?.ogTitle || frontmatter.title}
        />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content={imageWidth} />
        <meta property="og:image:height" content={imageHeight} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={'@' + frontmatter.author.twitter}
        />
        <meta
          name="twitter:title"
          content={frontmatter.meta?.ogTitle || frontmatter.title}
        />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <BlogPostSchema
        url={url}
        image={image}
        wordCount={wordCount.words}
        frontmatter={frontmatter}
      />
      <Block>
        <Box paddingTop={92} paddingBottom={32}>
          {isLongArticle ? (
            <>
              {frontmatter.showCoverImage ? (
                <Box marginX="auto" maxWidth="blogContent">
                  {titleSection}
                </Box>
              ) : (
                <Box marginX="auto" maxWidth="largeBlock">
                  <SidebarLayout main={titleSection} sidebar={<div />} />
                </Box>
              )}
              <Box marginX="auto" maxWidth="largeBlock">
                <SidebarLayout
                  sidebar={
                    <StickySidebar
                      headings={headings}
                      title={frontmatter.title}
                      titleId={titleId}
                    />
                  }
                  main={content}
                />
                <SidebarLayout main={footer} />
              </Box>

              <Box marginX="auto" maxWidth="blogContent">
                <SidebarLayout main={<SecondaryCTA />} />
              </Box>
            </>
          ) : (
            <>
              <Box marginX="auto" maxWidth="blogContent">
                <Box className={isLongArticle ? 'use-small-outset ' : ''}>
                  {titleSection}
                  {content}
                  {footer}
                </Box>
              </Box>
              <Box maxWidth="largeBlock">
                <SecondaryCTA />
              </Box>
            </>
          )}
        </Box>
      </Block>

      <FeaturedBlogArticles
        articles={data.relatedPosts.edges.map(
          edge => edge.node.frontmatter.slug,
        )}
        heading="More content from Swarmia"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!, $relatedSlugs: [String!]!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      wordCount {
        words
      }
      frontmatter {
        date(formatString: "MMM D, YYYY")
        dateIso: date(formatString: "YYYY-MM-DD")
        slug
        title
        articleType
        tocDepth
        showCoverImage
        tweetId
        author {
          name
          jobTitle
          bio
          linkedin
          twitter
          profilepicture {
            childImageSharp {
              gatsbyImageData(width: 80, height: 80)
            }
          }
        }
        meta {
          description
          seoTitle
          ogTitle
          imageAlt
          image {
            childImageSharp {
              gatsbyImageData(width: 1000, quality: 90)
              original {
                src
                width
                height
              }
            }
          }
        }
      }
    }
    relatedPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { slug: { in: $relatedSlugs } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
          }
        }
      }
    }
  }
`

const sidebarTopPx = 100

function StickySidebar({
  headings,
  title,
  titleId,
}: {
  headings: Heading[] | null
  title: string
  titleId: string
}) {
  const { ref, inView } = useInView({
    rootMargin: `-${sidebarTopPx}px 0px 0px 0px`,
    fallbackInView: true,
    initialInView: true,
  })

  return (
    <div style={{ height: '100%' }}>
      <div ref={ref} />
      <SidebarContent isSticky={!inView}>
        {headings ? (
          <TableOfContents
            title={title}
            titleId={titleId}
            headings={headings}
          />
        ) : null}
      </SidebarContent>
    </div>
  )
}

const SidebarContent = styled.div<{ isSticky: boolean }>`
  position: sticky;
  top: ${sidebarTopPx}px;
  max-height: calc(100vh - ${sidebarTopPx + 40}px);
  overflow-y: auto;
  z-index: 10;

  // This is when the sidebar layout collapses
  @media screen and (max-width: ${theme.breakpoints['large']}) {
    display: none;
  }

  ${props =>
    props.isSticky
      ? `
  opacity: 0.25;
  transition: opacity 0.15s ease;
  &:hover {
    opacity: 1;
  }`
      : ``}
`

const Bio = styled.div`
  margin: 0 0 40px 0;
  color: ${theme.colors.black500};
  display: flex;
  flex-direction: row;

  .bio-avatar {
    margin-right: 24px;

    .gatsby-image-wrapper {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }

  .bio-name {
    color: black;
    font-weight: 600;
  }
`
