import React from 'react'
import styled from 'styled-components'
import theme from '../theme'
import { Heading } from '../tocUtils'
import Box from './primitives/Box'

interface Props {
  title: string
  titleId: string
  headings: Heading[]
  currentHeadingId?: string
}

export function TableOfContents({
  title,
  titleId,
  headings,
  currentHeadingId,
}: Props) {
  return (
    <TableOfContentsWrapper>
      <a href={`#${titleId}`}>
        <Box.h4 paddingLeft={12} paddingBottom={8} font="h5">
          {title}
        </Box.h4>
      </a>
      <HeadingList headings={headings} currentHeadingId={currentHeadingId} />
    </TableOfContentsWrapper>
  )
}

function HeadingList({
  headings,
  currentHeadingId,
}: {
  headings: Heading[]
  currentHeadingId?: string
}) {
  return (
    <ul>
      {headings.map(heading => (
        <li key={heading.id}>
          <a
            href={`#${heading.id}`}
            className={currentHeadingId === heading.id ? 'active' : ''}
          >
            {heading.text}
          </a>
          {heading.children ? (
            <HeadingList
              headings={heading.children}
              currentHeadingId={currentHeadingId}
            />
          ) : null}
        </li>
      ))}
    </ul>
  )
}

const TableOfContentsWrapper = styled.div`
  & > ul {
    padding-left: 0;
  }

  ul {
    list-style: none;
    font-size: ${theme.fontSizes[1]};
    font-weight: 400;

    li {
      padding-left: 0;
    }

    ul,
    li {
      margin-bottom: 0;
      margin-top: 0;
    }

    a {
      display: block;
      padding: ${theme.space[1]} ${theme.space[2]};
      line-height: 1.5;

      &.active {
        border-radius: ${theme.radii['medium']};
        background: #f3f6fc;
      }

      &:hover {
        border-radius: ${theme.radii['medium']};
        background: ${theme.colors['hoverHighlight']};
      }
    }
  }
`
